import Layout from '@/layout/index.vue'
import { $t } from '@/plugins/i18n'

const deliveryRouter = {
  path: '/delivery',
  name: 'delivery',
  component: Layout,
  redirect: '/delivery/freight',
  meta: {
    icon: 'Van',
    title: $t('menus.hsdelivery-manage'),
    i18n: true,
    rank: 100,
  },
  children: [
    {
      path: '/delivery/freight',
      name: 'freight',
      component: () => import('@/views/delivery/freight.vue'),
      meta: {
        title: $t('menus.hsdelivery-freight'),
        i18n: true,
        icon: 'Money',
        rank: 1001,
        isHidden: false,
        keepAlive: true,
      },
    },
  ],
}

export default deliveryRouter
