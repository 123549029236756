// 静态路由
import homeRouter from './home'
import errorRouter from './error'
import remainingRouter from './remaining'
import { RouteRecordRaw, RouteComponent } from 'vue-router'

//动态路由
// import systemRouter from './system'
import mallRouter from './mall'
import configRouter from './config'
import goodsRouter from './goods'
import orderRouter from './order'
import customerRouter from './customer'
import deliveryRouter from './delivery'

import { ascending, formatTwoStageRoutes, formatFlatteningRoutes } from '../utils'

// 原始静态路由（未做任何处理）
const routes = [homeRouter, errorRouter]
const asyncRoutes = [
  // systemRouter,
  configRouter,
  mallRouter,
  goodsRouter,
  orderRouter,
  customerRouter,
  deliveryRouter,
]

// 导出处理后的静态路由（三级及以上的路由全部拍成二级）
export const constantRoutes: Array<RouteRecordRaw> = formatTwoStageRoutes(
  formatFlatteningRoutes(ascending(routes))
)

// 用于渲染菜单，保持原始层级
export const constantMenus: Array<RouteComponent> = ascending(routes).concat(...remainingRouter)

export const asyncMenus: Array<RouteRecordRaw> = ascending(asyncRoutes)
